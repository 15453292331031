import { getTreatments } from 'store/split/splitSelectors';
import { setCampaign, clearCampaign } from 'store/campaign/campaignActions';
import { fetchCampaign } from 'lib/clients/fetchCampaign';
import isEmpty from 'lodash/isEmpty';
import { VPP_CAMPAIGN } from 'config';
import { getPathName } from 'store/router/routerSelectors';
import { useFeature } from '@optimizely/react-sdk';

export default async ({ store }) => {
  const state = store.getState();
  const pathName = getPathName(state);

  if (!pathName) return;

  try {
    const campaign = await fetchCampaign();

    const [isLocalCampaignEnabled, variables] = useFeature('qantas-hotels-blue-banner-message', { autoUpdate: true });

    const localCampaignConfig = variables;

    if (!isEmpty(campaign)) {
      store.dispatch(setCampaign(campaign));
    } else {
      const { vpp } = getTreatments(state);

      if (vpp?.treatment === 'on') {
        store.dispatch(setCampaign(VPP_CAMPAIGN));
      } else if (isLocalCampaignEnabled && !isEmpty(localCampaignConfig)) {
        store.dispatch(setCampaign(localCampaignConfig));
      } else {
        store.dispatch(clearCampaign());
      }
    }
  } catch (e) {
    store.dispatch(clearCampaign());
  }
};
